<template>
  <aside>
    <header>
      <h2>View Competition</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main v-if="competition">
      <div class="info-grid">
        <div class="info-section">
          <h4>Competition name</h4>
          {{competition.title}}
        </div>

        <div class="info-section">
          <h4>Competition type</h4>
          Person vs. Person
        </div>

        <div class="info-section">
          <h4>Leaderboard type</h4>
          Points earned from shoutouts
        </div>

        <div class="info-section">
          <h4>Participating channel</h4>
          {{competition.user_group.title}}
        </div>
        

        <div class="info-section">
          <h4>Current period</h4>
          <span><strong>Start: </strong>{{$DateTime.fromISO(competition.start_time).toLocaleString($DateTime.DATETIME_SHORT)}}</span>
          <span><strong>End: </strong>{{$DateTime.fromISO(competition.end_reset_time).toLocaleString($DateTime.DATETIME_SHORT)}}</span>
        </div>

        <div class="info-section">
          <h4>Award earned</h4>
          {{competition.award.title}} <span class="points-earned">{{$formatPoints(competition.award.points)}}</span>
        </div>

      </div>

      <div class="info-section" v-if="result_history.length > 0">
        <h4>Result history</h4>

        <ul>
          <li v-for="(result, index) in result_history" :key="`rh_${competition.id}_${index}`">
            <a href="#" @click.prevent="showResults(result)">
              {{result.title}}
            </a>
          </li>
        </ul>
      </div>

    </main>

    <footer>
      <button class="btn btn-sm btn-muted-link" :class="{'loading': loading}" @click="archiveCompetition">Archive</button>
      <button class="btn btn-sm btn-secondary" :class="{'loading': loading}" @click="close">Close</button>

    </footer>
  </aside>
</template>

<script>
export default {
  props: ['data'],
  computed: {
    groups() {
      return this.$store.state.groups;
    },
    team() {
      return this.$store.state.team;
    },
    available_awards() {
      if(this.$store.state.awards === null) return null;
      return _.filter(this.$store.state.awards, a => !a.is_bot);
    },
    loading() {
      return this.$store.state.loading;
    }
  },
  data() {
    return {
      competition: null,
      result_history: []
    }
  },
  created() {
    this.competition = _.cloneDeep(this.data)
    this.populateResults()
  },
  methods: {
    close() {
      this.$emit('done')
    },
    showResults({results}) {
      this.$root.$emit(
        'openModal',
        'Competitions/Results',
        {
          leaderboard: results,
          competition: this.competition
        },
        () => {}
      )
    },
    async archiveCompetition() {
      if(!confirm('Are you sure you want to archive this competition?')) return;

      this.$store.commit('loading', true)
      await this.$api.Competitions.archive(this.competition.id)
      this.$store.commit('loading', false)

      this.$emit('done', 'ARCHIVED_COMPETITION')
    },
    async populateResults() {
      const resp = await this.$api.Competitions.get_result_history(this.competition.id)
      this.result_history = resp;
    }
  }
}
</script>

<style lang="scss" scoped>
.info-section {
  font-size: 14px;
  > h4 {
    font-weight: 700;
    font-size: 12px;
    margin: 0 0 5px;
    color: $muted-text;
    line-height: 1em;
  }

  > span {
    display: block;
    > strong {
      color: $muted-text;
    }
  }

  .points-earned {
    background: $green;
    display: inline-block;
    color: #fff;
    font-size: 12px;
    line-height: 1em;
    padding: 3px 4px;
    border-radius: 3px;
  }
}

.info-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -15px -15px 0;

  > div {
    flex: 0 0 auto;
    padding: 15px;
  }
}
</style>